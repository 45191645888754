<template>
  <div class="relative bg-grey-400 pt-20 px-30 my-10">
    <h3 class="text-20 font-bold text-white font-khand pb-15">{{ gender !== 'All' ? 'Søg blandt alle de skønne ' + config.brandName + '-' + (gender === 'girl' ? 'piger' : 'mænd') : 'Søg bland alle de skønne ' + config.brandName + '-piger/mænd' }}:</h3>
    <form class="w-full items-center" action="soeg" method="get" @submit.prevent="search()">
      <div class="relative pb-15">
        <select v-model="sex" aria-label="Vælg køn" class="text-16 font-bold tracking-widest w-full px-15 py-5 h-[40px] outline-none appearance-none">
          <option value="All">Køn</option>
          <option value="girl" :selected="gender === 'piger'">Pige</option>
          <option value="boy" :selected="gender === 'mænd'">Mand</option>
        </select>
        <div class="bg-red-600 square-40 absolute top-0 right-0 bg-svg-arrow2-white bg-16 bg-no-repeat bg-center pointer-events-none"></div>
      </div>
      <div class="relative pb-15">
        <select v-model="age" aria-label="Vælg alder" class="text-16 font-bold tracking-widest w-full px-15 py-5 h-[40px] outline-none appearance-none">
          <option value="All" selected>Alder</option>
          <option value="1">{{ "< 20" }}</option>
          <option value="2">21-25</option>
          <option value="3">26-30</option>
          <option value="4">31-35</option>
          <option value="5">36-40</option>
          <option value="6">41-45</option>
          <option value="7">{{ "> 46" }}</option>
        </select>
        <div class="bg-red-600 square-40 absolute top-0 right-0 bg-svg-arrow2-white bg-16 bg-no-repeat bg-center pointer-events-none"></div>
      </div>
      <div class="relative pb-15">
        <select v-model="release" aria-label="Vælg udgivelsesår" class="text-16 font-bold tracking-widest w-full px-15 py-5 h-[40px] outline-none appearance-none">
          <option value="" selected>Udgivet</option>
          <option value="2007">2007</option>
          <option value="2011">2011</option>
          <option value="2013">2013</option>
          <option value="2014">2014</option>
          <option value="2015">2015</option>
          <option value="2016">2016</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
        </select>
        <div class="bg-red-600 square-40 absolute top-0 right-0 bg-svg-arrow2-white bg-16 bg-no-repeat bg-center pointer-events-none"></div>
      </div>
      <div class="pb-15">
        <input type="search" v-model="city" aria-label="Indtast by" placeholder="By" class="w-full text-16 h-[40px] tracking-widest px-15 py-5 outline-none appearance-none" />
      </div>
      <div class="pb-15">
        <input type="search" v-model="name" aria-label="Indtast navn" placeholder="Navn" class="w-full text-16 h-[40px] tracking-widest px-15 py-5 outline-none appearance-none" />
      </div>
      <button :disabled="!sex" type="submit" aria-label="Søg" class="bg-red-600 h-30 w-[100px] left-1/2 -translate-x-1/2 bottom-[-5px] mx-auto relative z-5 text-18 font-bold text-white uppercase focus:outline-none">
        Søg
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  gender: {
    type: String,
  },
});

const { public: config } = useRuntimeConfig();

/**
 * Router object.
 */
const router = useRouter();
const route = useRoute();

/**
 * Current search terms.
 * If there are searchParams, apply them to current
 */
const sex = ref(route.query && route.query.person_gender ? route.query.person_gender : props.gender === 'girl' ? 'girl' : props.gender === 'boy' ? 'boy' : 'All');
const age = ref(route.query && route.query.age ? route.query.age : 'All');
const release = ref(route.query && route.query.person_published_year_name ? route.query.person_published_year_name : '');
const city = ref(route.query && route.query.city ? route.query.city : '');
const name = ref(route.query && route.query.name ? route.query.name : '');

/**
 * Executes search form.
 *
 * @returns void
 */
const search = () => {
  const searchString =
    'person_gender=' + (sex.value ? sex.value : 'All') +
    '&person_age=' + (age.value ? age.value : 'All') +
    '&person_published_year_name=' + (release.value ? release.value : '') +
    '&city=' + (city.value ? city.value : '') +
    '&name=' + (name.value ? name.value : '');

  router.push('/soeg-personer?' + searchString);
};
</script>
